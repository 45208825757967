/* 
1em = 16px

   0 -  400px:    Small screens                   -->  400/16 = 25em
 400 -  600px:    Phone                           -->  600/16 = 37.5em
 600 -  900px:    Tablet portrait                 -->  900/16 = 56.25em
 900 - 1200px:    Tablet landscape                --> 1200/16 = 75em
1200 - 1800px:    is where normal styles apply    --> 1800/16 = 112.5em
1800px +     :    Big desktop 
 */
/* $shadow-bottom: 0 4px 6px -1px rgba(0, 0, 0, 0.6);
$shadow-around: 0 0 16px -1px rgba(0, 0, 0, 0.4);
$shadow-right: 5px 5px 5px -1px rgba(0, 0, 0, 0.4); */
@keyframes moveInLeft {
  0% {
    transform: translateX(-10rem);
    opacity: 0; }
  80% {
    transform: translateX(1rem); }
  100% {
    transform: translate(0);
    opacity: 1; } }

@keyframes moveInRight {
  0% {
    transform: translateX(10rem);
    opacity: 0; }
  80% {
    transform: translateX(-1rem); }
  100% {
    transform: translate(0);
    opacity: 1; } }

@keyframes moveInBottom {
  0% {
    transform: translateY(3rem);
    opacity: 0; }
  100% {
    transform: translate(0);
    opacity: 1; } }

/* @keyframes popUpLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0.8;
  }

  100% {
    transform: translateX(-0);
    opacity: 1;
  }
} */
@keyframes scaleUp {
  0% {
    transform: scale(0.95);
    opacity: 0.8; }
  100% {
    transform: scale(1);
    opacity: 1; } }

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

a {
  text-decoration: none;
  color: inherit; }

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-weight: 400;
  color: #f2ece4; }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 25em) {
    html {
      font-size: 30%; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 37.5em) {
    html {
      font-size: 40%; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 18.75em) {
    html {
      font-size: 30%; } }

body {
  line-height: 1.6;
  font-family: "Montserrat", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #947664; }
  @media only screen and (max-width: 56.25em) {
    body {
      font-size: 2rem; } }
  @media only screen and (max-width: 25em) {
    body {
      font-size: 2rem; } }

::selection {
  background-color: #425c56;
  color: #f2ece4; }

.app-container {
  position: relative;
  background-image: linear-gradient(to bottom right, rgba(242, 236, 228, 0.6), #f2ece4); }

.heading-1 {
  letter-spacing: 5px;
  font-family: "Allison", cursive;
  font-size: 8rem;
  font-weight: 400;
  color: #f2ece4; }
  @media only screen and (max-width: 75em) {
    .heading-1 {
      font-size: 6.5rem; } }
  @media only screen and (max-width: 56.25em) {
    .heading-1 {
      font-size: 6rem; } }
  @media only screen and (max-width: 25em) {
    .heading-1 {
      font-size: 8rem; } }
  @media only screen and (min-width: 37.5em) and (max-height: 31.25em) {
    .heading-1 {
      font-size: 5rem; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 3) and (max-width: 37.5em) {
    .heading-1 {
      font-size: 7rem; } }

.heading-2 {
  margin-bottom: 6rem;
  padding: 0 2rem;
  letter-spacing: 5px;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  color: #947664; }
  @media only screen and (max-width: 56.25em) {
    .heading-2 {
      font-size: 3rem; } }

.heading-3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #425c56; }
  @media only screen and (max-width: 56.25em) {
    .heading-3 {
      font-size: 2.2rem; } }

.heading-4 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #947664; }
  @media only screen and (max-width: 56.25em) {
    .heading-4 {
      font-size: 2rem; } }

.heading-5 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #947664; }
  @media only screen and (max-width: 75em) {
    .heading-5 {
      font-size: 1.4rem; } }
  @media only screen and (max-width: 56.25em) {
    .heading-5 {
      font-size: 1.8rem; } }

.about {
  grid-column: center-start / center-end; }
  .about__container {
    display: grid;
    grid-template-rows: repeat(16, 3.2vw);
    grid-template-columns: repeat(21, 3.2vw);
    justify-content: center; }
    @media only screen and (max-width: 75em) {
      .about__container {
        grid-template-rows: repeat(16, 4.1vw);
        grid-template-columns: repeat(21, 4.1vw); } }
    @media only screen and (max-width: 56.25em) {
      .about__container {
        grid-template-rows: repeat(20, 4.4vw);
        grid-template-columns: repeat(20, 4.4vw); } }
    @media only screen and (max-width: 37.5em) {
      .about__container {
        grid-template-rows: repeat(46, 4vw);
        grid-template-columns: repeat(21, 4vw); } }
  .about__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
    padding: 2rem;
    font-size: 1.8rem;
    border-radius: 50%;
    border: 0.8rem solid #947664;
    background-color: #fff;
    color: #947664;
    transition: all 0.3s; }
    .about__item:hover {
      transform: scale(1.1);
      z-index: 30;
      box-shadow: 0 0 16px -1px #947664; }
    .about__item--1 {
      grid-row: 3 / span 6;
      grid-column: 1 / span 6; }
      @media only screen and (max-width: 37.5em) {
        .about__item--1 {
          grid-row: 4 / span 9;
          grid-column: 2 / span 9;
          z-index: 2; } }
    .about__item--2 {
      grid-row: 7 / span 4;
      grid-column: 5 / span 4;
      z-index: 10; }
      @media only screen and (max-width: 56.25em) {
        .about__item--2 {
          grid-row: 7 / span 5;
          grid-column: 5 / span 5; } }
      @media only screen and (max-width: 37.5em) {
        .about__item--2 {
          grid-row: 12 / span 8;
          grid-column: 1 / span 8; } }
    .about__item--3 {
      grid-row: 1 / span 8;
      grid-column: 7 / span 8; }
      @media only screen and (max-width: 37.5em) {
        .about__item--3 {
          grid-row: 1 / span 12;
          grid-column: 9 / span 12; } }
    .about__item--4 {
      grid-row: 8 / span 6;
      grid-column: 8 / span 6;
      z-index: 3; }
      @media only screen and (max-width: 56.25em) {
        .about__item--4 {
          grid-row: 8 / span 7;
          grid-column: 9 / span 7; } }
      @media only screen and (max-width: 37.5em) {
        .about__item--4 {
          grid-row: 23 / span 11;
          grid-column: 11 / span 11; } }
    .about__item--5 {
      grid-row: 2 / span 6;
      grid-column: 14 / span 6;
      margin: 0 -2rem 0 2rem; }
      @media only screen and (max-width: 56.25em) {
        .about__item--5 {
          grid-row: 5 / span 7;
          grid-column: 13 / span 7;
          transform: translate(2vw, -2vw); } }
      @media only screen and (max-width: 37.5em) {
        .about__item--5 {
          grid-row: 13 / span 11;
          grid-column: 10 / span 11;
          z-index: 3;
          margin: 0;
          transform: translate(-2vw, 0); } }
    .about__item--6 {
      grid-row: 10 / span 6;
      grid-column: 2 / span 6; }
      @media only screen and (max-width: 56.25em) {
        .about__item--6 {
          grid-row: 11 / span 6;
          transform: translateY(-1.5vw); } }
      @media only screen and (max-width: 37.5em) {
        .about__item--6 {
          grid-row: 19 / span 9;
          grid-column: 3 / span 9;
          transform: translate(0, 0); } }
    .about__item--7 {
      grid-row: 11 / span 6;
      grid-column: 13 / span 6;
      z-index: 2;
      margin: 0 2rem 0 -2rem; }
      @media only screen and (max-width: 56.25em) {
        .about__item--7 {
          grid-row: 14 / span 7;
          grid-column: 6 / span 7; } }
      @media only screen and (max-width: 37.5em) {
        .about__item--7 {
          grid-row: 27 / span 12;
          grid-column: 1 / span 12;
          margin: 0; } }
    .about__item--8 {
      grid-row: 7 / span 6;
      grid-column: 16 / span 6;
      z-index: 1; }
      @media only screen and (max-width: 56.25em) {
        .about__item--8 {
          grid-row: 13 / span 7;
          grid-column: 14 / span 7;
          transform: translate(-2vw, -2vw); } }
      @media only screen and (max-width: 37.5em) {
        .about__item--8 {
          grid-row: 34 / span 13;
          grid-column: 9 / span 13;
          transform: translate(0, 0); } }
  .about__text--question {
    line-height: 1.3;
    font-weight: 600; }
  .about__text--answer {
    font-family: "Allison", cursive;
    line-height: 1;
    letter-spacing: 2px;
    font-size: 3.5rem;
    font-weight: 400;
    text-shadow: 0.2px 0.2px #947664; }
    @media only screen and (max-width: 75em) {
      .about__text--answer {
        font-size: 3.4rem; } }
    @media only screen and (max-width: 56.25em) {
      .about__text--answer {
        max-width: 90%;
        margin: 0 auto;
        font-size: 3.6rem; } }

.btn {
  padding: 1.6rem 4rem;
  border-radius: 10rem;
  border: none;
  box-shadow: 5px 5px 5px -1px #947664;
  font-size: 1.8rem;
  font-weight: 600;
  transition: all 0.2s;
  cursor: pointer; }
  .btn:hover {
    transform: scale(1.1); }
  .btn:active {
    outline: none;
    transform: translateY(-0.1rem); }

.btn--dark {
  background-image: linear-gradient(to bottom right, rgba(66, 92, 86, 0.8), rgba(39, 54, 50, 0.98));
  color: #fff; }

.btn--light {
  background-color: #fff;
  color: #947664; }

.close {
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: 2rem;
  right: 2rem;
  fill: #947664;
  transition: all 0.2s;
  cursor: pointer; }
  @media only screen and (max-width: 75em) {
    .close {
      width: 4rem;
      height: 4rem; } }
  .close:hover {
    filter: drop-shadow(5px 5px 5px rgba(148, 118, 100, 0.4));
    transform: scale(1.1); }
  .close:active {
    transform: translateY(-0.1rem); }

.contact {
  grid-row: contact-start / contact-end;
  grid-column: full-start / full-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  background-color: #f7f5f3; }
  .contact__text {
    text-align: center;
    letter-spacing: 2px;
    font-family: "Allison", cursive;
    font-size: 5rem;
    text-shadow: 0.2px 0.2px #273632;
    color: #273632; }
    @media only screen and (max-width: 56.25em) {
      .contact__text {
        padding: 0 8rem; } }

.contact-form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200000000;
  background-color: rgba(242, 236, 228, 0.3);
  backdrop-filter: blur(10px) brightness(50%);
  transition: all 0.3s;
  animation: scaleUp 0.5s ease-out; }

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 90%;
  min-width: 50%;
  max-height: 90%;
  min-height: 70%;
  padding: 8rem;
  max-width: 90%;
  min-width: 60%;
  max-height: 90%;
  min-height: 70%;
  margin: 0;
  display: grid;
  grid-template-rows: repeat(5, 4.5rem) 1fr min-content;
  grid-template-columns: 1fr;
  justify-items: center;
  border-radius: 10px;
  background-color: #f2ece4;
  transition: all 0.3s; }
  @media only screen and (max-width: 75em) {
    .form {
      min-width: 70%;
      min-height: 70%;
      padding: 6rem; } }
  @media only screen and (max-width: 56.25em) {
    .form {
      min-width: 80%;
      padding: 6rem; } }
  @media only screen and (max-width: 37.5em) {
    .form {
      min-width: 95%;
      padding: 3rem; } }
  @media only screen and (max-width: 25em) {
    .form {
      min-height: 60%; } }
  .form__input, .form__text {
    border-radius: 5px;
    border: none;
    border-bottom: 3px solid transparent;
    font-family: inherit;
    font-size: inherit;
    transition: all 0.3s; }
  .form__input {
    width: 100%;
    display: block;
    padding: 1rem 2rem; }
    .form__input:focus {
      outline: none;
      box-shadow: 0 4px 6px -1px rgba(148, 118, 100, 0.6); }
    .form__input:focus:invalid {
      border-bottom: 3px solid #a81732; }
    .form__input::placeholder {
      color: #bd9f8c; }
  .form__label {
    display: block;
    justify-self: start;
    padding: 1rem 2rem;
    font-weight: 600;
    color: #947664;
    transition: all 0.3s; }
    .form__label--name {
      grid-row: 1 / span 1; }
    .form__label--mail {
      grid-row: 3 / span 1; }
    .form__label--message {
      grid-row: 5 / 6; }
  .form__input:placeholder-shown + .form__label,
  .form__text:placeholder-shown + .form__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(3rem); }
  .form__text {
    width: 100%;
    min-height: 100%;
    padding: 1rem 2rem;
    outline: none;
    resize: none; }
    .form__text::placeholder {
      font-size: inherit;
      color: #bd9f8c; }
    .form__text:focus:invalid {
      border-bottom: 3px solid #a81732; }
  .form__buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4.3rem; }
    .form__buttons--send {
      width: 1.8rem;
      height: 1.8rem;
      margin-left: 1rem;
      fill: currentColor;
      transform: translateY(0.2rem); }

.experience {
  grid-column: full-start / full-end; }
  @media only screen and (max-width: 75em) {
    .experience {
      grid-column: center-start / center-end; } }
  .experience__date {
    font-size: 1.6rem !important;
    font-weight: 600 !important; }
    @media only screen and (max-width: 56.25em) {
      .experience__date {
        font-size: 1.8rem !important; } }

.vertical-timeline-element-content {
  box-shadow: 0 4px 6px -1px rgba(148, 118, 100, 0.6); }

#experience__description {
  font-size: 1.6rem;
  font-weight: 400; }
  @media only screen and (max-width: 56.25em) {
    #experience__description {
      font-size: 1.8rem; } }

.logo {
  height: 20vh;
  text-align: center;
  font-family: "Allison", cursive;
  font-size: 5rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s; }
  @media only screen and (max-width: 75em) {
    .logo {
      position: absolute;
      width: 5rem;
      height: 5rem;
      top: 2rem;
      left: 2rem;
      padding-top: 2px;
      font-size: 3rem;
      font-weight: 600;
      border-radius: 50%;
      background-color: #f7f5f3;
      animation: moveInLeft 1s ease-out; }
      .logo:hover {
        transform: scale(1.1);
        box-shadow: 0 0 16px -1px #947664; } }
  .logo:hover {
    filter: drop-shadow(5px 5px 5px rgba(148, 118, 100, 0.4)); }
  .logo:active {
    text-shadow: 0.2px 0.2px #947664; }

.projects {
  grid-column: full-start / full-end; }
  @media only screen and (max-width: 75em) {
    .projects {
      grid-column: center-start / center-end; } }
  .projects__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(42rem, 1fr));
    justify-content: center;
    gap: 6rem;
    padding: 0 8rem; }
    @media only screen and (max-width: 75em) {
      .projects__container {
        padding: 0 2rem; } }

.project {
  display: grid;
  grid-template-rows: 2rem repeat(2, max-content) 1fr max-content 2rem;
  grid-template-columns: 20rem 1fr;
  column-gap: 2rem; }
  @media only screen and (max-width: 56.25em) {
    .project {
      column-gap: 3rem; } }
  .project__link {
    text-decoration: none; }
    .project__link--img {
      grid-row: 1 / -1;
      grid-column: 1 / span 1;
      align-self: center;
      display: block;
      width: 20rem;
      height: 20rem;
      border-radius: 50%;
      overflow: hidden;
      transition: all 0.3s; }
      .project__link--img:hover {
        box-shadow: 0 0 16px -1px #947664;
        transform: scale(1.06); }
        @media only screen and (max-width: 75em), only screen and (hover: none) {
          .project__link--img:hover {
            box-shadow: none;
            transform: scale(1); } }
    .project__link--title {
      grid-row: 2 / span 1; }
  .project__link--img:hover .project__caption {
    opacity: 1;
    transform: translate(-50%, -50%); }
    @media only screen and (max-width: 75em), only screen and (hover: none) {
      .project__link--img:hover .project__caption {
        transform: translateX(-50%); } }
  .project__figure {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.8rem solid #947664;
    overflow: hidden; }
    @media only screen and (max-width: 75em), only screen and (hover: none) {
      .project__figure {
        position: relative; } }
  .project__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left; }
  .project__link--img:hover .project__img {
    filter: blur(3px) brightness(80%); }
    @media only screen and (max-width: 75em), only screen and (hover: none) {
      .project__link--img:hover .project__img {
        filter: blur(0) brightness(100%); } }
  .project__caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 20%);
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px #947664;
    font-size: 1.7rem;
    color: #f2ece4;
    opacity: 0;
    transition: all 0.5s;
    backface-visibility: hidden; }
    @media only screen and (max-width: 75em), only screen and (hover: none) {
      .project__caption {
        width: 100%;
        height: max-content;
        padding: 1rem;
        bottom: 0;
        left: 0;
        background-color: rgba(66, 92, 86, 0.8);
        transform: translateY(50%) rotate(-10deg) scale(1.2);
        opacity: 1; } }
  .project__title {
    color: #425c56; }
  .project__label {
    grid-row: 3 / span 1;
    font-style: italic; }
  .project__desc {
    grid-row: 4 / span 1;
    padding: 2rem 0; }
  .project__tools {
    grid-row: 5 / span 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem; }
    .project__tools--logo {
      height: 3rem; }

.skills {
  grid-column: center-start / center-end; }
  .skills__tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 8rem; }
    @media only screen and (max-width: 75em) {
      .skills__tools {
        padding: 0 6rem; } }
    @media only screen and (max-width: 37.5em) {
      .skills__tools {
        gap: 6rem; } }
  .skills__tool-img {
    height: 5rem;
    filter: drop-shadow(5px 5px 5px rgba(148, 118, 100, 0.4)); }
    @media only screen and (max-width: 56.25em) {
      .skills__tool-img {
        height: 6rem; } }

.social-icon {
  width: 2.5rem;
  height: 2.5rem;
  margin: 1rem;
  fill: #947664;
  transition: all 0.3s; }
  .social-icon:hover {
    filter: drop-shadow(5px 5px 5px rgba(148, 118, 100, 0.4));
    transform: scale(1.2); }
  .social-icon:active {
    transform: translateY(-0.1rem); }

.success {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 200000000;
  background-color: rgba(242, 236, 228, 0.3);
  backdrop-filter: blur(10px) brightness(50%);
  transition: all 0.3s;
  animation: scaleUp 0.5s ease-out; }
  .success__card {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 90%;
    min-width: 50%;
    max-height: 90%;
    min-height: 70%;
    padding: 8rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f2ece4;
    transition: all 0.3s; }
    @media only screen and (max-width: 75em) {
      .success__card {
        min-width: 70%;
        min-height: 70%;
        padding: 6rem; } }
    @media only screen and (max-width: 56.25em) {
      .success__card {
        min-width: 80%;
        padding: 6rem; } }
    @media only screen and (max-width: 37.5em) {
      .success__card {
        min-width: 95%;
        padding: 3rem; } }
    @media only screen and (max-width: 25em) {
      .success__card {
        min-height: 60%; } }
  .success__gif {
    width: 30%;
    height: auto;
    margin-top: -20%; }
  .success__text {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: #947664; }

.background {
  grid-row: header-start / header-end;
  grid-column: full-start / full-end;
  z-index: -10;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right bottom, rgba(242, 236, 228, 0.5), #947664), url(../public/img/header.jpg);
  background-size: cover;
  background-blend-mode: screen;
  background-position: top left;
  mask: radial-gradient(#f2ece4 0, #f2ece4 70%, rgba(242, 236, 228, 0) 95%, rgba(242, 236, 228, 0) 0);
  box-shadow: 0 4px 6px -1px rgba(148, 118, 100, 0.6); }

.footer {
  grid-row: header-mid-end / header-end;
  grid-column: sidebar-start / sidebar-end;
  z-index: 10;
  position: fixed;
  width: 15rem;
  padding: 2rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media only screen and (max-width: 75em) {
    .footer {
      grid-row: foot-start / foot-end;
      grid-column: full-start / full-end;
      position: relative;
      width: 100%;
      padding: 6rem 2rem; } }
  .footer__legal {
    font-size: 1.4rem;
    text-align: center; }
    @media only screen and (max-width: 56.25em) {
      .footer__legal {
        font-size: 1.8rem; } }

.app-container {
  width: 100%;
  display: grid;
  grid-template-rows: [header-start] 20vh [header-mid-start] 1fr [header-mid-end] max-content [header-end] repeat(5, [section-start] max-content [section-end]) [contact-start] 50vh [contact-end];
  grid-template-columns: [sidebar-start] 15rem [sidebar-end full-start] minmax(6rem, 1fr) [center-start] 1fr [center-end] minmax(6rem, 1fr) [full-end]; }
  @media only screen and (max-width: 75em) {
    .app-container {
      grid-template-rows: [header-start] 20vh [header-mid-start] 1fr [header-mid-end] max-content [header-end] repeat(5, [section-start] max-content [section-end]) [contact-start] 50vh [contact-end foot-start] max-content [foot-end];
      grid-template-columns: [full-start] minmax(5rem, 7rem) [center-start] 1fr [center-end] minmax(5rem, 7rem) [full-end]; } }
  @media only screen and (max-width: 37.5em) {
    .app-container {
      grid-template-columns: [full-start] 1rem [center-start] 1fr [center-end] 1rem [full-end]; } }

.header {
  grid-row: header-start / header-end;
  grid-column: full-start / full-end;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 8rem 2rem 0 2rem;
  box-shadow: 0 4px 6px -1px rgba(148, 118, 100, 0.6);
  background-image: linear-gradient(to bottom right, rgba(66, 92, 86, 0.8), rgba(39, 54, 50, 0.98));
  color: #f2ece4; }
  @media only screen and (max-width: 75em) {
    .header {
      padding: 8rem 8rem 0 8rem; } }
  @media only screen and (max-width: 56.25em) {
    .header {
      display: grid;
      grid-template-rows: max-content minmax(20%, 60%);
      grid-template-columns: 1fr;
      justify-items: center;
      align-content: end;
      gap: 2rem; } }
  .header__img {
    height: 60%;
    max-height: 50rem;
    filter: sepia(30%) drop-shadow(3px -9px 10px rgba(0, 0, 0, 0.6));
    animation: moveInLeft 1s ease-out; }
    @media only screen and (max-width: 56.25em) {
      .header__img {
        height: 100%;
        max-height: 60rem; } }
    @media only screen and (max-width: 25em) {
      .header__img {
        max-height: 70rem; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 2.5) and (max-width: 37.5em) {
      .header__img {
        max-height: 48rem; } }
  .header__text {
    align-self: flex-start;
    transform: translateY(20vh); }
    @media only screen and (max-width: 56.25em) {
      .header__text {
        width: 100%;
        align-self: flex-end;
        transform: translateY(0); } }
    .header__text--first {
      align-self: center;
      animation: moveInLeft 1s ease-out; }
    .header__text--second {
      text-align: end;
      animation: moveInRight 1s ease-out; }

.navbar {
  grid-row: header-start/ header-mid-end;
  grid-column: sidebar-start / sidebar-end;
  top: 0;
  z-index: 100;
  position: fixed;
  width: 15rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 12rem);
  padding: 2rem;
  letter-spacing: 2px;
  font-weight: 600;
  transition: all 0.4s; }
  @media only screen and (max-width: 75em) {
    .navbar {
      grid-column: full-start / full-end;
      width: 100%;
      height: 8rem;
      flex-direction: row;
      justify-content: space-between; } }
  .navbar__menu-icon {
    display: none;
    z-index: 1000;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #f2ece4;
    transition: all 0.3s; }
    .navbar__menu-icon:hover {
      transform: scale(1.1);
      box-shadow: 0 0 16px -1px #947664; }
    .navbar__menu-icon--item {
      width: 100%;
      height: 100%;
      padding: 0.5rem;
      fill: #947664; }
    @media only screen and (max-width: 75em) {
      .navbar__menu-icon {
        display: block;
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        animation: moveInRight 1s linear; } }
  .navbar__menu {
    list-style: none; }
    @media only screen and (max-width: 75em) {
      .navbar__menu {
        position: absolute;
        right: -100%;
        opacity: 1; } }
    .navbar__menu--active {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      font-size: 2rem;
      background-color: #f2ece4;
      transition: all 0.3s; }
      @media only screen and (max-width: 56.25em) {
        .navbar__menu--active {
          font-size: 2.5rem; } }
  .navbar__item--link {
    padding: 0.5rem;
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #947664 50%);
    background-size: 230%;
    cursor: pointer;
    transition: all 0.4s; }
    .navbar__item--link:hover, .navbar__item--link:active {
      background-position: 100%;
      color: #f2ece4;
      transform: translateX(1rem); }

.scroll-up {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 180;
  width: 5rem;
  height: 5rem;
  fill: #947664;
  border-radius: 50%;
  background-color: #f2ece4;
  opacity: 1;
  cursor: pointer;
  transition: scale 0.3s, opacity 1s; }
  .scroll-up--hidden {
    opacity: 0; }
  .scroll-up:hover {
    transform: scale(1.1);
    box-shadow: 0 0 16px -1px #947664; }

.side {
  grid-row: header-start / header-end;
  grid-column: sidebar-start / sidebar-end;
  justify-self: end;
  position: fixed;
  z-index: 100;
  height: 100vh;
  display: grid;
  grid-template-rows: 20vh repeat(3, max-content) 1fr;
  justify-items: center;
  row-gap: 2rem;
  padding-top: 1rem; }
  @media only screen and (max-width: 75em) {
    .side {
      display: none; } }
  .side__dot {
    background-color: #947664; }
    .side__dot--2, .side__dot--3, .side__dot--4 {
      width: 5px;
      height: 5px;
      border-radius: 50%; }
    .side__dot--1 {
      grid-row: 1 / span 1;
      width: 3px;
      height: 100%;
      border-radius: 3px; }
    .side__dot--2 {
      grid-row: 2 / span 1; }
    .side__dot--3 {
      grid-row: 3 / span 1; }
    .side__dot--4 {
      grid-row: 4 / span 1; }
    .side__dot--5 {
      grid-row: 5 / span 1;
      width: 3px;
      height: 100%;
      border-radius: 3px; }

.section {
  padding: 8rem 0; }
  @media only screen and (max-width: 75em) {
    .section {
      padding: 6rem 0; } }
